
	import * as Config from '@/Config';
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { Event, Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewBackground extends ViewBase {
		/**
		 * @return HTMLElement
		 */
		protected get fromElement(): HTMLElement {
			return [...document.querySelectorAll('#' + this.from)].pop() as HTMLElement;
		}

		/**
		 * @return HTMLElement
		 */
		protected get toElement(): HTMLElement {
			return [...document.querySelectorAll('#' + this.to)].pop() as HTMLElement;
		}

		/**
		 * @return HTMLElement
		 */
		private get element(): HTMLElement {
			return this.$el as HTMLElement;
		}

		/**
		 * @return HTMLElement
		 */
		private get parentNode(): HTMLElement {
			return this.element.parentNode as HTMLElement;
		}

		/**
		 * Automatically bind functions to scope
		 *
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnResize'];

		/**
		 * Type of background
		 *
		 * @var string
		 */
		@Prop({ default: 'b' })
		public background!: string;

		/**
		 * ID of the element it should stretch from
		 *
		 * @var string
		 */
		@Prop()
		public from!: string;

		/**
		 * Normalized offset percentage. 0.0 = start, 1.0 = end
		 *
		 * @var number
		 */
		@Prop({ default: 0 })
		public fromOffset!: number;

		/**
		 * ID of the element it should stretch to
		 *
		 * @var string
		 */
		@Prop()
		public to!: string;

		/**
		 * Normalized offset percentage. 0.0 = start, 1.0 = end
		 *
		 * @var number
		 */
		@Prop()
		public toOffset!: number;

		/**
		 * @type number
		 */
		private height: number = 10;

		/**
		 * @type number
		 */
		private top: number = 0;

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Manager.Resize.add(this.element, this.parentNode, this.Handle_OnResize);
			Event.Bus.on('background:resize', this.Handle_OnResize);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Manager.Resize.remove(this.element);
			Event.Bus.off('background:resize', this.Handle_OnResize);
		}

		/**
		 * @return void
		 */
		@mounted
		protected setup(): void {
			this.update();
		}

		/**
		 * @return void
		 */
		protected update(): void {
			const fromTop = this.fromElement ? Utility.offsetTop(this.fromElement) : 0;
			const fromHeight = this.fromElement ? Utility.offsetHeight(this.fromElement) : 0;
			const toTop = this.toElement ? Utility.offsetTop(this.toElement) : fromTop + fromHeight;
			const toHeight = this.toElement
				? Utility.offsetHeight(this.toElement)
				: Utility.offsetHeight(this.parentNode) - toTop;

			// console.log('resizing', this.from, this.fromElement, '-', fromTop, fromHeight, toTop, toHeight);

			this.top = fromTop + fromHeight * this.fromOffset;
			this.height = toTop + toHeight * this.toOffset - this.top + 1; // +1 to prevent rounding errors
		}

		/**
		 * @return Promise<void>
		 */
		@Watch('$store.state.transitioning')
		protected async Handle_OnTransition(): Promise<void> {
			// Only do this for Safari
			if (!Config.Environment.IS_SAFARI) {
				return;
			}

			// Trigger updates constantly during transitions
			if (this.$store.state.transitioning) {
				Utility.Interval.add(() => this.update(), 1000 / 60, this.symbol);
			} else {
				Utility.Interval.remove(this.symbol);
			}
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnResize(): Promise<void> {
			this.update();
		}
	}
