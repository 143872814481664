
	import gsap from 'gsap';
	import ViewBase from '@/View/Base';
	import { Utility } from 'buck-ts';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewProgress extends ViewBase {
		/**
		 * @type number
		 */
		protected get currentValue(): number {
			return this.isAnimating ? this.animatingValue : this.value;
		}

		/**
		 * @type string
		 */
		protected get subcaptionDisplay(): string {
			return this.subcaption || this.valueDisplay;
		}

		/**
		 * @type string
		 */
		protected get valueDisplay(): string {
			return Utility.Math.round(this.currentValue * 100, 2) + '%';
		}

		/**
		 * @type string
		 */
		@Prop()
		public caption!: string;

		/**
		 * @type string
		 */
		@Prop()
		public subcaption!: string;

		/**
		 * @type number
		 */
		@Prop({
			default: 0.0,
		})
		public value!: number;

		/**
		 * @type number
		 */
		private animatingValue: number = 0;

		/**
		 * @type boolean
		 */
		private isAnimating: boolean = true;

		/**
		 * @type boolean
		 */
		private hasAnimated: boolean = false;

		/**
		 * @type string
		 */
		public animateIn(): void {
			this.hasAnimated = true;
			this.isAnimating = true;
			this.animatingValue = 0;
			this.animateValue(this.value);
		}

		/**
		 * @param number value
		 * @return void
		 */
		protected animateValue(value: number): void {
			gsap.to(this, {
				animatingValue: value,
				duration: 1,
				ease: 'power2.inOut',
			});
		}

		/**
		 * @param IntersectionObserverEntry entry
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersectEnter(entry: IntersectionObserverEntry): Promise<void> {
			if (this.hasAnimated) {
				return;
			}

			this.animateIn();
		}
	}
