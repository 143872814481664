
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { assetRef } from '@/Manager/Asset';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewLoopingSequence extends ViewBase {
		/**
		 * The canvas element to draw the video to
		 *
		 * @type HTMLCanvasElement
		 */
		@Ref('canvas')
		private readonly canvas!: HTMLCanvasElement;

		/**
		 * @type CanvasRenderingContext2D
		 */
		private get context(): CanvasRenderingContext2D {
			return this.canvas.getContext('2d')!;
		}

		/**
		 * Automatically bind functions to scope
		 *
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnInitialSequenceEnded', 'Handle_OnInterval'];

		/**
		 * @type boolean
		 */
		@Prop({ default: true })
		protected autoplay!: boolean;

		/**
		 * Size of the canvas
		 *
		 * @type string
		 */
		@Prop({ default: 512 })
		protected canvasSize!: number;

		/**
		 * Size of the canvas
		 *
		 * @type string
		 */
		@Prop({ default: 512 })
		protected containerSize!: number;

		/**
		 * Amount of initial frames
		 *
		 * @type number
		 */
		@Prop()
		protected initialFramesCount!: number;

		/**
		 * Path to the initial sequence folder
		 *
		 * @type string
		 */
		@Prop()
		protected initialFramesFolder!: string;

		/**
		 * Amount of idle frames
		 *
		 * @type number
		 */
		@Prop()
		protected idleFramesCount!: number;

		/**
		 * The URL of the video to play when the initial video is finished.
		 *
		 * @type string
		 */
		@Prop()
		protected idleFramesFolder!: string;

		/**
		 * @type string
		 */
		private currentFrameFolder!: string;

		/**
		 * @type boolean
		 */
		private hasStarted: boolean = false;

		/**
		 * @type number
		 */
		private frameIndex: number = 0;

		/**
		 * @type string
		 */
		private intervalName: string | symbol = '';

		/**
		 * @type boolean
		 */
		private isPlaying: boolean = false;

		/**
		 * @type boolean
		 */
		private playingState: string = 'initial';

		/**
		 * @type Record<string,HTMLImageElement[]>
		 */
		private loadedFrames: Record<string, HTMLImageElement[]> = {
			idle: [],
			initial: [],
		};

		/**
		 * The starting delta from global state we'll use to diff our frameIndex
		 *
		 * @type number
		 */
		private startDelta: number = 0;

		/**
		 * @return void
		 */
		public play(): void {
			this.isPlaying = true;
			this.startDelta = this.$store.state.deltaFps;
		}

		/**
		 * @return void
		 */
		public wait(): void {
			if (this.canvas) {
				this.canvas.width = this.canvasSize;
			}
		}

		/**
		 * @return void
		 */
		@mounted
		protected setup(): void {
			this.preloadFrames('initial');
			this.preloadFrames('idle');
		}

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			this.intervalName = Utility.Interval.add(this.Handle_OnInterval, 1000 / 30);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Utility.Interval.remove(this.intervalName);
		}

		/**
		 * @return void
		 */
		protected drawFrame(): void {
			// Nothing to draw to
			if (!this.canvas || !this.context) {
				return;
			}

			// Get stack of frames from map of initial or idle
			const currentFrames = this.loadedFrames[this.playingState];

			// Determine how many frames total are in this state
			const totalFrames = this.playingState === 'idle' ? this.idleFramesCount : this.initialFramesCount;

			// Save local frameIndex
			if (this.isPlaying) {
				if (this.playingState === 'initial') {
					this.frameIndex = Math.min(
						this.initialFramesCount,
						(this.$store.state.deltaFps - this.startDelta) % (totalFrames + 1),
					);
				} else {
					this.frameIndex = (this.$store.state.deltaFps - this.startDelta) % totalFrames;
				}
			}

			// Only draw if we have something to draw
			if (currentFrames[this.frameIndex] && this.canvas) {
				this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.context.drawImage(currentFrames[this.frameIndex], 0, 0, this.canvas.width, this.canvas.height);
			}

			// Don't advance
			if (!this.isPlaying) {
				return;
			}

			// Check if we need to switch to idle frames
			if (this.frameIndex >= this.initialFramesCount - 1 && this.playingState === 'initial') {
				this.playingState = 'idle';
				this.startDelta = this.$store.state.deltaFps;
			}
		}

		/**
		 * @param string type
		 * @return void
		 */
		private preloadFrames(type: string): void {
			const folder = type === 'initial' ? this.initialFramesFolder : this.idleFramesFolder;
			const totalFrames = type === 'initial' ? this.initialFramesCount : this.idleFramesCount;
			const frameObject = type === 'initial' ? this.loadedFrames.initial : this.loadedFrames.idle;

			// Load all images
			for (let i = 0; i < totalFrames; i++) {
				const frame = new Image();
				frame.src = assetRef(`${folder}/${String(i).padStart(2, '0')}.webp`);
				frameObject.push(frame);
			}

			// Reset active index
			this.frameIndex = 0;
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnInterval(): Promise<void> {
			this.drawFrame();
		}

		/**
		 * @param IntersectionObserverEntry entry
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersection(entry: IntersectionObserverEntry): Promise<void> {
			const playDelay = this.$store.state.transitioning ? 250 : 0;

			if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
				if (!this.hasStarted && this.autoplay) {
					this.hasStarted = true;

					// Play with delay for transitions
					setTimeout(() => this.play(), playDelay);
				}
			}
		}

		// endregion: Event Handlers
	}
