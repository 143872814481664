
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewTimelineMedia extends ViewBase {
		/**
		 * URL to media like webp, mp4, gif, etc
		 *
		 * @type string[]
		 */
		@Prop()
		public media!: string[];

		/**
		 * @param string media
		 * @return boolean
		 */
		protected isImage(media: string): boolean {
			return (
				media.endsWith('.webp') ||
				media.endsWith('.png') ||
				media.endsWith('.jpg') ||
				media.endsWith('.jpeg') ||
				media.endsWith('.gif')
			);
		}

		/**
		 * @param string media
		 * @return boolean
		 */
		protected isVideo(media: string): boolean {
			return media.endsWith('.mp4') || media.endsWith('.webm') || media.endsWith('.gif');
		}
	}
