var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-people-events background-light",attrs:{"id":"events"}},[_c('div',{staticClass:"card-group indented side-scroller"},[_vm._m(0),_c('div',{staticClass:"card card-content width-medium style-a"},[_c('figure',[_c('picture',[_c('img',{attrs:{"alt":"Adobe Creative Retreat","src":_vm.$asset('image/graphic/people-strategyspeakeasy.webp')}})])]),_vm._m(1),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"card card-content width-medium style-a"},[_c('figure',[_c('picture',[_c('img',{attrs:{"alt":"Adobe Creative Retreat","src":_vm.$asset('image/graphic/people-adoberetreat.webp')}})])]),_vm._m(4),_vm._m(5)]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card card-content width-medium style-a"},[_c('header',[_c('h5',[_vm._v("2023 Events @ BUCK")])]),_c('section',[_c('ul',[_c('li',[_vm._v("AGDA Open Studio: An open house event in Sydney")]),_c('li',[_vm._v("Adnight: Annual open house event for creative shops in Amsterdam")]),_c('li',[_vm._v("Synth Workshop with Antfood")]),_c('li',[_vm._v(" Cohort Assemble: Featuring 10 lightning talks from a diverse group of experience and creative tech practitioners with lots of space to snack, chat, share and network about the weirdly-shaped work we do ")]),_c('li',[_vm._v(" The Silicon: A short film screening with filmmaker Ricardo Villavicencio and Runway ML co-founder Alejandro Matamala ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("The Strategy Speakeasy")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" In 2023, we hosted our first ever Strategy Speakeasy — an intimate evening in BUCK’s New York office for strategists and creatives to come together and talk about process. We hosted industry experts in open discussion, learning from each other and bettering our collaborative practice, together. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card card-content width-medium style-a"},[_c('header',[_c('h5',[_vm._v("2023 Student Portfolio Reviews")])]),_c('section',[_c('ul',[_c('li',[_vm._v("St. Joost School Den Bosch and Patrick O’Keefe school visit")]),_c('li',[_vm._v("IDEP Barcelona student visit")]),_c('li',[_vm._v("De Kunst van HKU school visit")]),_c('li',[_vm._v("Filmakademie Baden-Württemberg student visit")]),_c('li',[_vm._v("Brooklyn Collaborative High School student tour and Q&A")]),_c('li',[_vm._v("Otis internship & recruitment day")]),_c('li',[_vm._v("CUNY School presentation")]),_c('li',[_vm._v("Cal Arts LA studio visit")]),_c('li',[_vm._v("Caine College of the Arts, Utah State University visit")]),_c('li',[_vm._v("Adobe team BUCK office visit with LA 2D animation team")]),_c('li',[_vm._v("Ringling College of Art and Design visit")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Adobe Creative Retreat")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" Global Head of Design, Brian Won, CD Jeni Wamberg, Sr. Designer Kenni Huang, and Designer Matthew Kam were invited to attend The 2023 Adobe Creative Retreat — a retreat for the new generation of graphic designers. They spoke on panels like “Land Your Dream Design Job” and reviewed portfolios to inspire and encourage budding artists. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card card-content width-medium style-a"},[_c('header',[_c('h5',[_vm._v("2023 Workshops, Cons & Retreats")])]),_c('section',[_c('ul',[_c('li',[_vm._v("Presence Platform Hackathon at Meta London")]),_c('li',[_vm._v("Meta AR Summit")]),_c('li',[_vm._v("Meta Virtual Convening on Economic Opportunity")]),_c('li',[_vm._v("SoDA Webinar with Intention CollectiveSoDA Webinar with UserTesting")]),_c('li',[_vm._v("ADCOLOR: Championing Diversity and Inclusion in Creative Industries")]),_c('li',[_vm._v("Game Developers Conference")]),_c('li',[_vm._v("FITC: Future of Innovation, Technology and Creativity")])])])])
}]

export { render, staticRenderFns }