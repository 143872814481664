
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base';
	import { Component, Inject } from 'vue-property-decorator';
	import { beforeDestroy, created } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project Scrollyteller Template
	 */
	@Component
	export default class ViewScene extends ViewBase {
		// Not implemented
	}
