
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewOrbs extends ViewBase {
		/**
		 * @type string
		 */
		@Prop()
		public type!: string;
	}
