import { Event } from 'buck-ts';

/**
 * @class Preloader
 * @package Manager
 * @project BuckImpact2024
 */
export class Preloader extends Event.Dispatcher {
	/**
	 * @type string[]
	 */
	protected assetList: Record<string, any> = {};

	/**
	 * @param string asset
	 * @return void
	 */
	public add(asset: string): void {
		// Already loading
		if (this.assetList[asset]) {
			return;
		}

		// Save asset to list
		this.assetList[asset] = {
			loaded: false,
		};

		// Load image
		if (this.isImage(asset)) {
			this.loadImage(asset);
		}

		// If video, skip
		else {
			// this.loadVideo(asset);

			this.assetList[asset].loaded = true;
		}

		// Debug
		// console.log('🔺 Adding:', asset);
	}

	/**
	 * @param string asset
	 * @return void
	 */
	protected loadImage(asset: string): void {
		const image = new Image();

		image.onload = () => {
			this.assetList[asset].loaded = true;
			this.assetList[asset].asset = image;

			// Debug
			// console.log('🔺 Loaded:', asset);

			// Check if all are loaded
			const loaded = Object.keys(this.assetList).filter((key) => {
				return this.assetList[key].loaded;
			});

			if (loaded.length === Object.keys(this.assetList).length) {
				this.dispatch('complete');
			}
		};

		image.src = asset;
	}

	/**
	 * @param string asset
	 * @return void
	 */
	// protected loadVideo(asset: string): void {
	// 	const video = document.createElement('video');

	// 	video.onload = () => {
	// 		this.assetList[asset].loaded = true;
	// 		this.assetList[asset].asset = video;

	// 		// Debug
	// 		console.log('🔺 Loaded:', asset);

	// 		// Check if all are loaded
	// 		const loaded = Object.keys(this.assetList).filter((key) => {
	// 			return this.assetList[key].loaded;
	// 		});

	// 		if (loaded.length === Object.keys(this.assetList).length) {
	// 			this.dispatch('complete');
	// 		}

	// 		console.log(loaded.length, Object.keys(this.assetList).length);
	// 	};

	// 	video.src = asset;
	// }

	/**
	 * @param string asset
	 * @return boolean
	 */
	protected isImage(asset: string): boolean {
		return asset.match(/\.(png|jpg|jpeg|gif|webp)$/i) !== null;
	}
}

// Singleton
export default new Preloader();
