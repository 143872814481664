import { IS_LOCAL, IS_SAFARI } from './Environment';

/**
 * @type string
 */
export const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID || 'G-PGW3RT8QGB';

/**
 * Available office locations to choose from
 *
 * @type string[]
 */
export const OFFICE_LOCATIONS = ['united-states', 'sydney', 'amsterdam', 'london'];

/**
 * Sequential order of the pages
 *
 * @type string[]
 */
export const PAGE_ORDER = ['PageOverview', 'Page2023', 'PagePeople', 'PageProgress', 'PagePlanet', 'PageFuture'];

/**
 * @type string
 */
export const STORAGE_KEY = 'buck-impact-report-2023';

/**
 * Skip preloader if we're local
 *
 * @type boolean
 */
export const USE_PRELOADER = true; // !IS_LOCAL;

/**
 * Safari doesn't support our videos yet, so this is temporary
 *
 * @type boolean
 */
export const USE_VIDEO_ANIMATIONS = !IS_SAFARI;
