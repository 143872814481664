
	import ViewBase from '@/View/Base';
	import ViewLoopingVideo from '@/View/LoopingVideo.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewPrimaryHero extends ViewBase {
		/**
		 * @type ViewLoopingVideo
		 */
		@Ref('loopingVideo')
		public loopingVideo!: ViewLoopingVideo;

		/**
		 * @type number
		 */
		@Prop({ default: 512 })
		public canvasSize!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 512 })
		public containerSize!: number;

		/**
		 * @type string
		 */
		@Prop()
		public description!: string;

		/**
		 * @type string
		 */
		@Prop()
		public title!: string;

		/**
		 * @type string
		 */
		@Prop()
		public type!: string;

		/**
		 * We should wait longer on a slide to animate in than on a fade
		 *
		 * @return void
		 */
		@mounted
		protected autoplayVideo(): void {
			const duration = this.$store.getters.transitionType === 'translate' ? 750 : 500;

			setTimeout(() => {
				this.loopingVideo && this.loopingVideo.play();
			}, duration);
		}
	}
