var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-2023-bcorphero"},[_c('figure',{staticClass:"crystal crystal-00"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 100}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-00.webp")}})])]),_c('figure',{staticClass:"crystal crystal-01"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 50}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-01.webp")}})])]),_c('figure',{staticClass:"crystal crystal-02"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 75}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-02.webp")}})])]),_c('figure',{staticClass:"crystal crystal-03"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 75}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-03.webp")}})])]),_c('figure',{staticClass:"crystal crystal-04"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 50}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-04.webp")}})])]),_c('figure',{staticClass:"crystal crystal-05"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 100}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-05.webp")}})])]),_c('figure',{staticClass:"crystal crystal-06"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 300}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-06.webp")}})])]),_c('figure',{staticClass:"crystal crystal-07"},[_c('div',{style:({
				transform: `translateY(${_vm.parallax.ratio.full * 200}px)`,
			})},[_c('img',{attrs:{"aria-hidden":"true","src":require("@/Asset/image/graphic/2023-bcorp-crystal-06.webp")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }