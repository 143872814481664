var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-planet-emissionsdata background-light scrollable-cards",attrs:{"id":"emissionsData"}},[_vm._m(0),_c('div',{staticClass:"card-group-wrapper"},[_c('div',{staticClass:"card-group indented side-scroller"},[_c('article',{staticClass:"card card-infographic width-medium style-a"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-emissions-bylocation.webp'),"width":"200"}})]),_vm._m(1)]),_vm._m(2)]),_c('article',{staticClass:"card card-infographic width-medium style-a"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-emissions-byemployee.webp'),"width":"200"}})]),_vm._m(3)]),_vm._m(4)]),_c('article',{staticClass:"card card-infographic width-medium style-a"},[_c('figure',[_c('picture',[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-emissions-wheel.webp'),"width":"100%"}})])]),_vm._m(5)]),_c('article',{staticClass:"card card-infographic width-medium style-a"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-emissions-total.webp'),"width":"200"}})]),_vm._m(6)]),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"container-secondary container-xlarge"},[_c('h5',[_vm._v("2023 Emissions Data")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('figcaption',{staticClass:"text-center"},[_c('small',[_vm._v("mtCO2e")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Emissions By Location")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('figcaption',{staticClass:"text-center"},[_c('small',[_vm._v("mtCO2e")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Emissions By Employee")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('h5',[_vm._v(" BUCK Amsterdam and BUCK NY are our most emissions-efficient offices by both headcount and client service volume. ")]),_c('p',[_vm._v(" As a creative company, our carbon emissions are distinct. We don’t create industrial products or own fleets of fossil-fuel burning vehicles. Instead, BUCK’s most emissions-intensive areas of operation come from electricity use across our offices. ")]),_c('p',[_vm._v(" As we get together in office more, we will continue to partner with Brightest to ensure our protocols and habits are as green as possible, minimizing our carbon impact whenever and wherever we can. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('figcaption',{staticClass:"text-center"},[_c('small',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("BUCK's Total Emissions")])])
}]

export { render, staticRenderFns }