var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-people-data2023 background-b color-people-dark scrollable-cards",attrs:{"id":"data2023"}},[_vm._m(0),_c('div',{staticClass:"card-group-wrapper"},[_c('div',{staticClass:"card-group side-scroller"},[_c('article',{staticClass:"card card-infographic no-footer no-content width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Total Staff","src":_vm.$asset('image/graphic/people-card-data-staff.webp')}})])]),_vm._m(1)]),_c('article',{staticClass:"card card-infographic no-footer no-content width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Tenure stats","src":_vm.$asset('image/graphic/people-card-data-tenure.webp')}})])]),_vm._m(2)]),_c('article',{staticClass:"card card-infographic no-footer no-content width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Department stats","src":_vm.$asset('image/graphic/people-card-data-department.webp')}})])]),_vm._m(3)]),_c('article',{staticClass:"card card-infographic width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Leadership stats","src":_vm.$asset('image/graphic/people-card-data-leadership.webp')}})])]),_vm._m(4),_vm._m(5)]),_c('article',{staticClass:"card card-infographic width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Office stats","src":_vm.$asset('image/graphic/people-card-data-office.webp')}})])]),_vm._m(6),_vm._m(7)]),_c('article',{staticClass:"card card-infographic width-medium"},[_c('figure',[_c('picture',{staticClass:"background full"},[_c('img',{attrs:{"alt":"Slack stats","src":_vm.$asset('image/graphic/people-card-data-slack.webp')}})])]),_vm._m(8),_vm._m(9)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"container-secondary container-xlarge"},[_c('h5',[_vm._v("2023 Data")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Total Staff")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Tenure")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Department")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Leadership")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('p',{staticClass:"type-highlight-2"},[_vm._v(" 20% of our leadership has been at BUCK for over 10 years and 10% began as interns. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Local vs. Remote")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('small',[_vm._v("*We encourage local workers to come in 1-3x a week ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h5',[_vm._v("Communication")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('p',{staticClass:"type-highlight-2"},[_vm._v(" As a global company, Slack kept us together in a big way. Our lifetime slack messages top 37.5 million, with 470 channels and 2,018 members in 2023 alone. ")]),_c('br'),_c('small',[_vm._v("We use Slack even more than Slack does.")])])
}]

export { render, staticRenderFns }