
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { embedGoogleAnalytics, embedMetaPixel, embedRedditPixel } from '@/Library/Analytics';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project BuckImpact2024
	 */
	@Component
	export default class ViewCookieBanner extends ViewBase {
		/**
		 * @type boolean
		 */
		public get showCookieDialog(): boolean {
			return typeof this.$store.state.cookies !== 'boolean';
		}

		/**
		 * @return void
		 */
		public cookiesAccept(): void {
			this.$store.dispatch('cookiesAccept');

			// Embed again
			embedGoogleAnalytics(Config.App.GOOGLE_ANALYTICS_ID);
		}

		/**
		 * @return void
		 */
		public cookiesDeny(): void {
			this.$store.dispatch('cookiesDeny');
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param MouseEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickAcceptCookies(e: MouseEvent): Promise<void> {
			e.preventDefault();

			this.cookiesAccept();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnClickDenyCookies(): Promise<void> {
			this.cookiesDeny();
		}

		// endregion: Event Handlers
	}
