/**
 * @type interface
 */
export interface IStoreState {
	cookies: boolean | undefined;
	currentRouteName: string;
	delta30fps: number;
	delta60fps: number;
	deltaFps: number;
	foo: string;
	headerUnstick: boolean;
	logoType: any;
	menuOpen: boolean;
	officeLocation: string;
	preloadComplete: boolean;
	previousRouteName: string;
	routeDirection: number;
	transitioning: boolean;
}

/**
 * @class State
 * @package Store
 * @project Scrollyteller Template
 */
const state: IStoreState = {
	cookies: undefined,
	currentRouteName: '',
	delta30fps: 0,
	delta60fps: 0,
	deltaFps: 0,
	foo: 'bar',
	headerUnstick: true,
	logoType: 'normal',
	menuOpen: false,
	officeLocation: 'united-states',
	preloadComplete: false,
	previousRouteName: '',
	routeDirection: 0,
	transitioning: false,
};

export default state;
