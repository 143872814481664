
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene/Overview
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewSceneOverviewCovid extends ViewBase {
		// /**
		//  * @return IKeyValue
		//  */
		// public get styleHeading00(): IKeyValue {
		// 	const ratio = Utility.Easing.easeInOutQuad(this.parallax.ratio.centerToQuarter);
		// 	return {
		// 		opacity: `${(1 - ratio) * 100}%`,
		// 		transform: `translateY(${ratio * 60}px)`,
		// 	};
		// }
		// /**
		//  * @return IKeyValue
		//  */
		// public get styleHeading01(): IKeyValue {
		// 	return {
		// 		opacity: `${(1 - this.parallax.ratio.centerToQuarter) * 100}%`,
		// 		transform: `translateY(${this.parallax.ratio.centerToQuarter * 60}px)`,
		// 	};
		// }
		// /**
		//  * @return IKeyValue
		//  */
		// public get styleParagraph00(): IKeyValue {
		// 	return {
		// 		opacity: `${(1 - this.parallax.ratio.centerToQuarter) * 100}%`,
		// 		transform: `translateY(${this.parallax.ratio.centerToQuarter * 60}px)`,
		// 	};
		// }
		// /**
		//  * @return IKeyValue
		//  */
		// public get styleParagraph01(): IKeyValue {
		// 	return {
		// 		opacity: `${(1 - this.parallax.ratio.centerToQuarter) * 100}%`,
		// 		transform: `translateY(${this.parallax.ratio.centerToQuarter * 60}px)`,
		// 	};
		// }
	}
