
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene/Future
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewSceneFutureBuckClouds extends ViewBase {
		/**
		 * @return boolean
		 */
		protected get useVideo(): boolean {
			return Config.Environment.HAS_WEBM_SUPPORT && !Config.Environment.IS_MOBILE;
		}

		/**
		 * @type HTMLVideoElement
		 */
		@Ref('video')
		protected readonly video!: HTMLVideoElement;

		/**
		 * @type boolean
		 */
		protected hasStarted: boolean = false;

		/**
		 * @param IntersectionObserverEntry[] entries
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersection(entry: IntersectionObserverEntry): Promise<void> {
			if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
				if (!this.hasStarted) {
					this.hasStarted = true;
					this.video && this.video.play();
				}
			}
		}
	}
