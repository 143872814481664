import * as Config from '@/Config';
import * as Library from '@/Library';
import * as Manager from '@/Manager';
import { beforeDestroy, mounted } from '@/Utility/Decorators';
import { Component, Vue } from 'vue-property-decorator';

/**
 * If we use @Component here, we can't called super methods, but if we
 * don't, we don't have access to lifecycle or other Vue methods.
 *
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package View
 * @project Scrollyteller Template
 */
@Component
export default class ViewBase extends Vue {
	/**
	 * @type string
	 */
	public cid: string = Math.random().toString(32).substr(2, 7);

	/**
	 * Class names we can apply to the element
	 *
	 * @type string[]
	 */
	public className: Record<string, boolean> = {};

	/**
	 * @type Record<string, any>
	 */
	public config: Record<string, any> = Config;

	/**
	 * @type string
	 */
	public name: string = '';

	/**
	 * Unique symbol for every class to reference
	 *
	 * @type symbol
	 */
	public symbol: symbol = Symbol();

	/**
	 * @type string
	 */
	protected animateState: string = 'out';

	/**
	 * Automatically bind functions to scope
	 *
	 * @type string[]
	 */
	protected bindings: string[] = [];

	/**
	 * @type boolean
	 */
	protected intersection: Record<string, boolean> = {
		any: false,
		full: false,
		half: false,
		quarter: false,
		threeQuarter: false,
	};

	/**
	 * @type number
	 */
	protected mountedAt: number = 0;

	/**
	 * @return Library.Parallax
	 */
	protected parallax: Library.Parallax = new Library.Parallax();

	/**
	 * Bind associated methods with class
	 *
	 * @type string[]
	 */
	public setupBindings(): void {
		for (const binding of this.bindings) {
			this.bind(binding);
		}
	}

	/**
	 * @param string methodName
	 * @return void
	 */
	protected bind(methodName: string): void {
		// @ts-ignore
		this[methodName] = this[methodName].bind(this);
	}

	/**
	 * @return void
	 */
	@mounted
	public attachParallax(): void {
		this.parallax.setElement(this.$el as HTMLElement);
		this.parallax.attachEvents();
	}

	/**
	 * @return void
	 */
	@mounted
	public attachIntersection(): void {
		Manager.Intersection.add(this.$el as HTMLElement, this.Handle_OnIntersection);
	}

	/**
	 * @return void
	 */
	@mounted
	public setMountedAt(): void {
		this.mountedAt = Date.now();
	}

	/**
	 * @return void
	 */
	@beforeDestroy
	public detachParallax(): void {
		this.parallax.detachEvents();
	}

	/**
	 * @return void
	 */
	@beforeDestroy
	public detachIntersection(): void {
		Manager.Intersection.remove(this.$el as HTMLElement);
	}

	// region: Event Handlers
	// ---------------------------------------------------------------------------

	/**
	 * @param IntersectionObserverEntry entry
	 * @return Promise<void>
	 */
	protected async Handle_OnIntersection(entry: IntersectionObserverEntry): Promise<void> {
		this.intersection.any = entry.isIntersecting;
		this.intersection.full = entry.intersectionRatio === 1;
		this.intersection.half = entry.intersectionRatio >= 0.5;
		this.intersection.quarter = entry.intersectionRatio >= 0.25;
		this.intersection.threeQuarter = entry.intersectionRatio >= 0.75;

		Vue.set(this.className, 'intersect-any', this.intersection.any);
		Vue.set(this.className, 'intersect-full', this.intersection.full);
		Vue.set(this.className, 'intersect-half', this.intersection.half);
		Vue.set(this.className, 'intersect-quarter', this.intersection.quarter);
		Vue.set(this.className, 'intersect-three-quarter', this.intersection.threeQuarter);

		if (this.intersection.any) {
			this.Handle_OnIntersectEnter(entry);
			this.$emit('intersect:enter');
		} else {
			this.Handle_OnIntersectExit(entry);
			this.$emit('intersect:exit');
		}
	}

	/**
	 * @param IntersectionObserverEntry entry
	 * @return Promise<void>
	 */
	protected async Handle_OnIntersectEnter(entry: IntersectionObserverEntry): Promise<void> {
		// Do nothing
	}

	/**
	 * @param IntersectionObserverEntry entry
	 * @return Promise<void>
	 */
	protected async Handle_OnIntersectExit(entry: IntersectionObserverEntry): Promise<void> {
		// Do nothing
	}

	// endregion: Event Handlers
}
