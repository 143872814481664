var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-planet-projects background-light",attrs:{"id":"projects"}},[_vm._m(0),_c('div',{staticClass:"side-scroller"},[_c('div',{staticClass:"indented card-group",style:({
				// transform: `translateY(${100 - parallax.ratio.centerToHalf * 100}px)`,
			})},[_c('article',{staticClass:"card card-project"},[_c('figure',{staticClass:"aspect-1x1"},[_c('picture',[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-project-2x4.webp')}})])]),_vm._m(1),_vm._m(2)]),_c('article',{staticClass:"card card-project"},[_c('figure',{staticClass:"aspect-1x1"},[_c('picture',[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-project-thelastwhiterhino.webp')}})])]),_vm._m(3),_vm._m(4)]),_c('article',{staticClass:"card card-project"},[_c('figure',{staticClass:"aspect-1x1"},[_c('picture',[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-project-ketikoti.webp')}})])]),_vm._m(5),_vm._m(6)]),_c('article',{staticClass:"card card-project"},[_c('figure',{staticClass:"aspect-1x1"},[_c('picture',[_c('img',{attrs:{"src":_vm.$asset('image/graphic/planet-project-kurzgesagt.webp')}})])]),_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"container-secondary container-xlarge"},[_c('h5',[_vm._v("2023 Pro- and Low-Bono Projects")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("2x4")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" A foundation with a simple idea: connecting donors to meaningful organizations that better the lives of women in this country. Our partnership developed the foundation’s visual and verbal identity with a new name, logo, and brand guidelines that pay homage to the strength of women helping women. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("The Last White Rhino")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" The Last White Rhino is a short film produced by Giant Ant, with support from BUCK's Spaceship Earth initiative. At its core, the film asks a profound question: How do we take responsibility for protecting what we have already damned? ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("Keti Koti")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" Together with the University of Amsterdam and Black Archives, we initiated a project on the occasion of Keti Koti (‘The Chain is Cut’), an annual celebration to mark the date slavery was abolished in Surinam and the ABC/SSS Islands. From research to design, from screen to angisa. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("Kurzgesagt")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" We teamed up with the folks at Kurzgesagt to redesign their website, sparking new curiosity for science, broadcasting their brand, and highlighting their full product offering. We drew inspiration from scientific materials — academic textbooks, annotated diagrams, etc. — to evoke a learning environment that still preserves Kurzgesagt’s playful vibe. ")])])
}]

export { render, staticRenderFns }