import * as Config from '@/Config';
import * as Manager from '@/Manager';
import { Event, Utility } from 'buck-ts';

/**
 * @interface IParallaxOptions
 */
export interface IParallaxOptions {
	$el?: HTMLElement;
}

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Library
 * @project Scrollyteller Template
 */
export class Parallax extends Event.Dispatcher {
	/**
	 * @type HTMLElement
	 */
	public $el!: HTMLElement;

	/**
	 * @type Record<string, number>
	 */
	public ratio: Record<string, number> = {
		bottom: 0,
		bottomReverse: 0,
		bottomTo3Quarter: 0,
		bottomToHalf: 0,
		bottomToQuarter: 0,
		center: 0,
		centerReverse: 0,
		centerTo3Quarter: 0,
		centerToHalf: 0,
		centerToQuarter: 0,
		full: 0,
		fullReverse: 0,
		fullTo3Quarter: 0,
		fullToHalf: 0,
		fullToQuarter: 0,
		inOut: 0,
		inOutReverse: 0,
		top: 0,
		topReverse: 0,
		topTo3Quarter: 0,
		topToHalf: 0,
		topToQuarter: 0,
	};

	/**
	 * @param IParallaxOptions options
	 */
	constructor(options: IParallaxOptions = {}) {
		super();

		// Bindings
		this.Handle_OnScroll = this.Handle_OnScroll.bind(this);
	}

	/**
	 * @return void
	 */
	public attachEvents(): void {
		Manager.Scroll.on('scroll', this.Handle_OnScroll);
	}

	/**
	 * @return void
	 */
	public detachEvents(): void {
		Manager.Scroll.off('scroll', this.Handle_OnScroll);
	}

	/**
	 * @param HTMLElement $el
	 * @return void
	 */
	public setElement($el: HTMLElement): void {
		this.$el = $el;
	}

	// region: Event Handlers
	// ---------------------------------------------------------------------------

	/**
	 * @param any e
	 * @return Promise<void>
	 */
	protected async Handle_OnScroll(e: any): Promise<void> {
		if (!this.$el) {
			return;
		}

		const rect = this.$el.getBoundingClientRect();

		this.ratio.center = Math.max(0, Math.min(1, (rect.top + rect.height / 2) / window.innerHeight));
		this.ratio.centerReverse = 1 - this.ratio.center;
		this.ratio.centerToHalf = Math.min(1, Math.max(0, (this.ratio.center - 0.5) / 0.5));
		this.ratio.centerToQuarter = Math.min(1, Math.max(0, (this.ratio.center - 0.25) / 0.75));
		this.ratio.centerTo3Quarter = Math.min(1, Math.max(0, (this.ratio.center - 0.75) / 0.25));

		this.ratio.bottom = Math.max(0, Math.min(1, (rect.top + rect.height) / window.innerHeight));
		this.ratio.bottomReverse = 1 - this.ratio.bottom;
		this.ratio.bottomToHalf = Math.min(1, Math.max(0, (this.ratio.bottom - 0.5) / 0.5));
		this.ratio.bottomToQuarter = Math.min(1, Math.max(0, (this.ratio.bottom - 0.25) / 0.75));
		this.ratio.bottomTo3Quarter = Math.min(1, Math.max(0, (this.ratio.bottom - 0.75) / 0.25));

		this.ratio.top = Math.max(0, Math.min(1, rect.top / window.innerHeight));
		this.ratio.topReverse = 1 - this.ratio.top;
		this.ratio.topToHalf = Math.min(1, Math.max(0, (this.ratio.top - 0.5) / 0.5));
		this.ratio.topToQuarter = Math.min(1, Math.max(0, (this.ratio.top - 0.25) / 0.75));
		this.ratio.topTo3Quarter = Math.min(1, Math.max(0, (this.ratio.top - 0.75) / 0.25));

		// Ratio.full is a ratio normalizing between top and bottom
		this.ratio.full = this.ratio.top + (this.ratio.bottom - this.ratio.top) / 2;
		this.ratio.fullReverse = 1 - this.ratio.full;
		this.ratio.fullToHalf = Math.min(1, Math.max(0, (this.ratio.full - 0.5) / 0.5));
		this.ratio.fullToQuarter = Math.min(1, Math.max(0, (this.ratio.full - 0.25) / 0.75));
		this.ratio.fullTo3Quarter = Math.min(1, Math.max(0, (this.ratio.full - 0.75) / 0.25));

		this.ratio.inOut = Utility.Math.transformRatio(this.ratio.full, 4);
		this.ratio.inOutReverse = 1 - this.ratio.inOut;
	}

	// endregion: Event Handlers
}
