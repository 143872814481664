
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene/Overview
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewSceneOverviewCompanyList extends ViewBase {
		/**
		 * @param IntersectionObserverEntry entry
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersectEnter(entry: IntersectionObserverEntry): Promise<void> {
			// Do nothing
		}

		/**
		 * @param IntersectionObserverEntry entry
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersectExit(entry: IntersectionObserverEntry): Promise<void> {
			// Do nothing
		}
	}
