import { bind } from '@/Utility/Decorators';
import { Event, Utility } from 'buck-ts';

/**
 * @type interface
 */
interface IResizeMonitor {
	callback: any;
	requester: HTMLElement;
	target: HTMLElement;
	uniqueKey: string;
}

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Manager
 * @project Scrollyteller Template
 */
export class Resize extends Event.Dispatcher {
	/**
	 * @type Map<string, any>
	 */
	private monitors: Record<string, IResizeMonitor> = {};

	/**
	 * @type ResizeObserverEntry[]
	 */
	private entries: ResizeObserverEntry[] = [];

	/**
	 * @return void
	 */
	private observer: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
		this.entries = entries;

		// Utility.Debounce.exec('debounced-resize', this.Handle_OnResizeObserver, 200, true);
		this.Handle_OnResizeObserver();
	});

	/**
	 * @param HTMLElement requester
	 * @param HTMLElement target
	 * @param function callback
	 * @return void
	 */
	public add(requester: HTMLElement, target: HTMLElement, callback: any): void {
		const uniqueKey = this.getUniqueKey();

		// Set data attribute
		this.monitors[uniqueKey] = {
			callback: callback,
			requester: requester,
			target: target,
			uniqueKey: uniqueKey,
		};

		// Observe
		this.observer.observe(target);
	}

	/**
	 * @param HTMLElement requester
	 * @param HTMLElement target
	 * @return void
	 */
	public remove(requester: HTMLElement): void {
		const uniqueKey = this.getKeyByRequester(requester);
		const monitor = this.monitors[uniqueKey];

		// Nothing to do here
		if (!monitor) {
			return;
		}

		// Remove callback
		delete this.monitors[uniqueKey];

		// Unobserve
		this.observer.unobserve(monitor.target);
	}

	/**
	 * @param HTMLElement requester
	 * @return IResizeMonitor|null
	 */
	protected getKeyByRequester(requester: HTMLElement): string {
		for (const key in this.monitors) {
			if (this.monitors[key].requester === requester) {
				return key;
			}
		}

		return '';
	}

	/**
	 * @param HTMLElement target
	 * @return string[]
	 */
	protected getKeysByTarget(target: HTMLElement): string[] {
		const keys: string[] = [];

		for (const key in this.monitors) {
			if (this.monitors[key].target === target) {
				keys.push(key);
			}
		}

		return keys;
	}

	/**
	 * @return string
	 */
	protected getUniqueKey(): string {
		return Math.random().toString(36).substring(2, 15);
	}

	/**
	 * @return Promise<void>
	 */
	@bind
	protected async Handle_OnResizeObserver(): Promise<void> {
		if (!this.entries) {
			return;
		}

		this.entries.forEach((entry: ResizeObserverEntry) => {
			const keys: string[] = this.getKeysByTarget(entry.target as HTMLElement);

			keys.forEach((key) => {
				const monitor = this.monitors[key];
				monitor.callback();
			});
		});
	}
}

// Singleton
export default new Resize();
