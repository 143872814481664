import { Event, Utility } from 'buck-ts';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Manager
 * @project Scrollyteller Template
 */
export class Scroll extends Event.Dispatcher {
	/**
	 * @type any
	 */
	private handler: any;

	/**
	 * @constructor
	 */
	constructor() {
		super();

		// Create debounced event
		this.handler = new Utility.Debounce(this.Handle_OnScroll.bind(this), 1000 / 60, true, true);
		this.Handle_OnScroll = () => this.handler.run();
	}

	/**
	 * @return void
	 */
	public attach(): void {
		window.addEventListener('scroll', this.Handle_OnScroll);
	}

	/**
	 * @return void
	 */
	public detach(): void {
		window.removeEventListener('scroll', this.Handle_OnScroll);
	}

	/**
	 * @return Promise<void>
	 */
	protected async Handle_OnScroll(): Promise<void> {
		this.trigger('scroll');
	}
}

// Singleton
export default new Scroll();
