
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewGlowText extends ViewBase {
		/**
		 * @return string[]
		 */
		public get characters(): string[] {
			return this.text.split('');
		}

		/**
		 * @type number
		 */
		@Prop({ default: 1 })
		public blurMax!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 0.25 })
		public blurMin!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 8 })
		public shadowMax!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 4 })
		public shadowMin!: number;

		/**
		 * @type string
		 */
		@Prop()
		public text!: string;

		/**
		 * @param number index
		 * @return number
		 */
		protected getBlurAmount(index: number): number {
			return ((this.blurMax - this.blurMin) / this.characters.length) * index + this.blurMin;
		}

		/**
		 * @param number index
		 * @return number
		 */
		protected getShadowAmount(index: number): number {
			return ((this.shadowMax - this.shadowMin) / this.characters.length) * index + this.shadowMin;
		}
	}
