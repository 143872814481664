var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-progress-cardgrid background-light",attrs:{"id":"cardGrid"}},[_c('div',{staticClass:"container-primary container-xlarge"},[_c('div',{staticClass:"grid grid-2x1-full"},[_c('article',{staticClass:"card card-content",attrs:{"id":"rndCard"}},[_c('figure',[_c('picture',[_c('img',{attrs:{"alt":"Research and Development","src":_vm.$asset('image/graphic/progress-rnd.webp')}})])]),_vm._m(0),_vm._m(1)]),_vm._m(2),_c('article',{staticClass:"card card-content",attrs:{"id":"gamingCard"}},[_c('figure',[_c('picture',[_c('img',{attrs:{"alt":"Gaming","src":_vm.$asset('image/graphic/progress-gaming.webp')}})])]),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"grid grid-1x1-full"},[_c('article',{staticClass:"card card-project style-d",attrs:{"id":"letsRevolutionCard"}},[_c('figure',[_c('picture',[_c('img',{attrs:{"alt":"Let's! Revolution!","src":_vm.$asset('image/graphic/progress-letsrevolution.webp')}})])]),_vm._m(5),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("R&D")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('ul',[_c('li',[_vm._v("24,684 hours total in 2023")]),_c('li',[_vm._v("3,769 hours developing IP Content")]),_c('li',[_vm._v("7,462.75 developing Games IP")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"card card-content",attrs:{"id":"technologyCard"}},[_c('header',[_c('h4',[_vm._v("Technology Optimists, Not Evangelists")])]),_c('section',[_c('p',[_vm._v(" In an era when technology reshapes every facet of our lives, human creativity is the cornerstone of progress. We see a world that’s changing quickly, bringing opportunity to "),_c('em',[_vm._v("think, work, and make differently")]),_vm._v(". ")]),_c('p',[_vm._v(" What this means is we don’t shy away from new technology, but we also understand that there are seen and hidden costs inherent to every adoption, every shift, and every rejection. We’re technology optimists — but never evangelists — because we understand that it’s always "),_c('em',[_vm._v("complicated")]),_vm._v(". While we won’t get everything right as we navigate the emerging tech landscape, we’ll always make business decisions around its use as the artists we are. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("Gaming")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_vm._v(" A few years back, we recognized original games as a prime business and creative opportunity for talent to grow in new arenas. As gamers ourselves, we know the medium’s unparalleled potential for immersion and cultural impact. ")]),_c('p',[_vm._v(" We first attempted a conventional path and sought partner publishers. However, in true BUCK fashion, we wanted to do things our own way — independently, trusting the capacity and prowess of our team with a catch — we gave ourselves one year from conception to completion. ")]),_c('p',[_vm._v(" Many roundtables, workshops, trials, tribulations, and triumphs later, our puzzle and strategy indie "),_c('em',[_vm._v("Let’s! Revolution!")]),_vm._v(" was born in 2023. ")]),_c('p',[_vm._v(" Serendipitously, after we self-published, we reached a deal with Hawthorn Games for its international release. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('h4',[_vm._v("Let’s! Revolution!")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('p',[_c('em',[_vm._v("Let’s! Revolution!")]),_vm._v(" had widespread critical acclaim, providing 6,800 hours of creative work to around 50 BUCKers over 15 months. We also received overwhelmingly positive feedback from those studio staff who worked on it, some calling it a career highlight! Here are some direct quotes from contributors: ")]),_c('p',[_vm._v(" “In my five years at BUCK, it was, without a doubt, one of my favorite collaborations.” ")]),_c('p',[_vm._v(" “It was a great opportunity to develop and learn new skills that people wouldn't normally get the opportunity to in client work.” ")]),_c('p',[_vm._v(" “Let’s! Revolution! was probably the most collaborative project I’ve ever worked on in my career.” ")])])
}]

export { render, staticRenderFns }