import { bind } from '@/Utility/Decorators';
import { Event, Utility } from 'buck-ts';

/**
 * @type interface
 */
interface IIntersectionMonitor {
	callback: any;
	requester: HTMLElement;
	uniqueKey: string;
}

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Manager
 * @project Scrollyteller Template
 */
export class Intersection extends Event.Dispatcher {
	/**
	 * @type Map<string, any>
	 */
	private monitors: Record<string, IIntersectionMonitor> = {};

	/**
	 * @type IntersectionObserverEntry[]
	 */
	private entries: IntersectionObserverEntry[] = [];

	/**
	 * @return void
	 */
	private observer: IntersectionObserver = new IntersectionObserver(
		(entries: IntersectionObserverEntry[]) => {
			this.entries = entries;

			// Utility.Debounce.exec('debounced-Intersection', this.Handle_OnIntersectionObserver, 200, true);
			this.Handle_OnIntersectionObserver();
		},
		{
			rootMargin: '0px',
			threshold: [0.0, 0.25, 0.5, 0.75, 1.0], // 0 = any part, 1 = full
		},
	);

	/**
	 * @param HTMLElement requester
	 * @param function callback
	 * @return void
	 */
	public add(requester: HTMLElement, callback: any): void {
		const uniqueKey = this.getUniqueKey();

		// Set data attribute
		this.monitors[uniqueKey] = {
			callback: callback,
			requester: requester,
			uniqueKey: uniqueKey,
		};

		// Observe
		this.observer.observe(requester);
	}

	/**
	 * @param HTMLElement requester
	 * @param HTMLElement target
	 * @return void
	 */
	public remove(requester: HTMLElement): void {
		const uniqueKeys = this.getKeysByRequester(requester);

		uniqueKeys.forEach((uniqueKey) => {
			// Unobserve
			this.observer.unobserve(this.monitors[uniqueKey].requester);

			// Remove callback
			delete this.monitors[uniqueKey];
		});
	}

	/**
	 * @param HTMLElement requester
	 * @return string[]
	 */
	protected getKeysByRequester(requester: HTMLElement): string[] {
		const keys: string[] = [];

		for (const key in this.monitors) {
			if (this.monitors[key].requester === requester) {
				keys.push(key);
			}
		}

		return keys;
	}

	/**
	 * @return string
	 */
	protected getUniqueKey(): string {
		return Math.random().toString(36).substring(2, 15);
	}

	/**
	 * @return Promise<void>
	 */
	@bind
	protected async Handle_OnIntersectionObserver(): Promise<void> {
		if (!this.entries) {
			return;
		}

		this.entries.forEach((entry: IntersectionObserverEntry, index: number) => {
			const keys: string[] = this.getKeysByRequester(entry.target as HTMLElement);

			keys.forEach((key) => {
				const monitor = this.monitors[key];
				monitor.callback(entry);
			});
		});
	}
}

// Singleton
export default new Intersection();
