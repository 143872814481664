import Preloader from './Preloader';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Manager
 * @project Scrollyteller Template
 */
export class Asset {
	/**
	 * @type Record<string, any>
	 */
	private assetMap: Record<string, any> = {};

	/**
	 * We are lowercasing the path to fix mixed up directory casings
	 *
	 * @return void
	 */
	public createIndex(): void {
		const contexts = [
			require.context('@/Asset/', true, /\.(webp|png|jpg|gif|webm|svg|mp3|mp4|ttf|otf|woff|woff2)$/),
		];

		contexts.forEach((context) => {
			context.keys().forEach((key: string) => {
				const formattedKey = key.replace('./', '').toLowerCase();

				if (!this.assetMap[formattedKey]) {
					this.assetMap[formattedKey] = context(key);
				}
			});
		});
	}

	/**
	 * @param string path
	 * @param boolean preferWebm
	 * @return any
	 */
	public getAsset(path: string, preferWebm: boolean = false): any {
		const normalizedPath: string = path.toLowerCase();
		let output = this.assetMap[normalizedPath] || '';

		if (preferWebm) {
			const webmVersion = normalizedPath.replace('.mp4', '.webm');

			if (this.assetMap[webmVersion]) {
				return this.assetMap[webmVersion];
			}
		}

		// Preload image asset (likely)
		Preloader.add(output);

		return output;
	}
}

/**
 * @param string pathTo
 * @return string
 */
export function assetRef(pathTo: string): any {
	if (pathTo?.trim().length < 1) {
		console.warn('🔺 Attempting to get empty asset');
		return '';
	}

	if (pathTo.indexOf('http') > -1) {
		return pathTo;
	}

	// Attempt to retrieve the asset using the pre-indexed map
	return instance.getAsset(pathTo);
}

// Singleton
const instance = new Asset();
instance.createIndex();

export default instance;
