
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { assetRef } from '@/Manager/Asset';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewLoopingVideo extends ViewBase {
		/**
		 * The canvas element to draw the video to
		 *
		 * @type HTMLCanvasElement
		 */
		@Ref('canvas')
		private readonly canvas!: HTMLCanvasElement;

		/**
		 * @type CanvasRenderingContext2D
		 */
		private get context(): CanvasRenderingContext2D {
			return this.canvas.getContext('2d')!;
		}

		/**
		 * Automatically bind functions to scope
		 *
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnInitialVideoEnded', 'Handle_OnInterval'];

		/**
		 * @type HTMLCanvasElement
		 */
		protected currentVideo!: HTMLVideoElement;

		/**
		 * @type boolean
		 */
		@Prop({ default: true })
		protected autoplay!: boolean;

		/**
		 * The static image we use instead of video
		 *
		 * @type string
		 */
		@Prop()
		protected backupImage!: string;

		/**
		 * Size of the canvas
		 *
		 * @type string
		 */
		@Prop({ default: 512 })
		protected canvasSize!: number;

		/**
		 * Size of the canvas
		 *
		 * @type string
		 */
		@Prop({ default: 512 })
		protected containerSize!: number;

		/**
		 * The URL of the intro video to play
		 *
		 * @type string
		 */
		@Prop()
		protected initialVideoUrl!: string;

		/**
		 * The URL of the video to play when the initial video is finished.
		 *
		 * @type string
		 */
		@Prop()
		protected idleVideoUrl!: string;

		/**
		 * @type boolean
		 */
		private hasStarted: boolean = false;

		/**
		 * @type HTMLVideoElement
		 */
		private initialVideo!: HTMLVideoElement;

		/**
		 * @type HTMLVideoElement
		 */
		private idleVideo!: HTMLVideoElement;

		/**
		 * @type string
		 */
		private intervalName: string | symbol = '';

		/**
		 * @return void
		 */
		public play(): void {
			this.playInitialVideo();
		}

		/**
		 * @return void
		 */
		public wait(): void {
			if (this.canvas) {
				this.canvas.width = this.canvasSize;
			}
		}

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			this.intervalName = Utility.Interval.add(this.Handle_OnInterval, 1000 / 30);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Utility.Interval.remove(this.intervalName);
		}

		/**
		 * @return void
		 */
		protected drawImage(): void {
			if (!this.canvas || !this.context || !this.backupImage) {
				return;
			}

			const image = new Image();
			image.src = this.backupImage;

			image.onload = () => {
				this.context.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
			};
		}

		/**
		 * @return void
		 */
		protected drawVideo(): void {
			if (!this.canvas || !this.context || !this.currentVideo) {
				return;
			}

			// Don't draw/clear anything if we're stuck at 0
			if (this.currentVideo.currentTime || this.currentVideo === this.initialVideo) {
				// Clear if we have buffering
				this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

				// Draw video
				this.context.drawImage(this.currentVideo, 0, 0, this.canvas.width, this.canvas.height);
			}
		}

		/**
		 * @return void
		 */
		@mounted
		protected preload(): void {
			this.loadInitialVideo();
			this.loadIdleVideo();

			// Check if we should play animations
			if (!Config.App.USE_VIDEO_ANIMATIONS) {
				this.drawImage();
				return;
			}
		}

		/**
		 * @return void
		 */
		private loadInitialVideo(): void {
			this.initialVideo = document.createElement('video');
			this.initialVideo.loop = false;
			this.initialVideo.muted = true;
			this.initialVideo.playsInline = true;

			const sources = [
				// {
				// 	src: this.initialVideoUrl.replace('webm', 'mov'),
				// 	type: 'video/quicktime',
				// },
				{
					src: this.initialVideoUrl,
					type: 'video/webm',
				},
			];

			sources.forEach((sourceData) => {
				const sourceElement = document.createElement('source');
				sourceElement.src = sourceData.src;
				sourceElement.type = sourceData.type;
				this.initialVideo.appendChild(sourceElement);
			});

			this.initialVideo.addEventListener('loadeddata', () => {
				if (!this.canvas) {
					return;
				}

				this.canvas.width = this.canvasSize;
				this.canvas.height = this.canvasSize;
				this.currentVideo = this.initialVideo;
				this.currentVideo.currentTime = 0;

				// Draw first frame
				this.drawVideo();
			});

			this.initialVideo.addEventListener('ended', this.Handle_OnInitialVideoEnded);
		}

		/**
		 * @return void
		 */
		private loadIdleVideo(): void {
			this.idleVideo = document.createElement('video');
			this.idleVideo.loop = true;
			this.idleVideo.muted = true;
			this.idleVideo.playsInline = true;

			const sources = [
				// {
				// 	src: assetRef('video/IR100_Diamond_-idle.mp4'),
				// 	type: 'video/quicktime',
				// },
				{
					src: this.idleVideoUrl,
					type: 'video/webm',
				},
			];

			sources.forEach((sourceData) => {
				const sourceElement = document.createElement('source');
				sourceElement.src = sourceData.src;
				sourceElement.type = sourceData.type;
				this.idleVideo.appendChild(sourceElement);
			});
		}

		/**
		 * @return void
		 */
		private playInitialVideo(): void {
			this.initialVideo.play().catch((error) => {
				console.error('Error attempting to play video:', error);
			});
			this.idleVideo.pause();
			this.currentVideo = this.initialVideo;
		}

		/**
		 * @return void
		 */
		private playIdleVideo(): void {
			this.initialVideo.pause();
			this.idleVideo.play().catch((error) => {
				console.error('Error attempting to play video:', error);
			});
			this.currentVideo = this.idleVideo;
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param Event e
		 * @return Promise<void>
		 */
		protected async Handle_OnInitialVideoEnded(e: Event): Promise<void> {
			this.playIdleVideo();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnInterval(): Promise<void> {
			this.drawVideo();
		}

		/**
		 * @param IntersectionObserverEntry[] entries
		 * @return Promise<void>
		 */
		protected async Handle_OnIntersection(entry: IntersectionObserverEntry): Promise<void> {
			if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
				if (!this.hasStarted && this.autoplay) {
					this.hasStarted = true;
					this.play();
				}
			}
		}

		// endregion: Event Handlers
	}
