var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-overview-timelineone background-light",attrs:{"id":"timelineOne"}},[_c('ol',{staticClass:"view-timeline timeline extended-overhang timeline-after-solid"},[_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"variation":"00","media":[
						_vm.$asset('image/graphic/overview-timeline-2004-01.webp'),
						_vm.$asset('image/graphic/overview-timeline-2004-00.webp'),
					]}}),_c('h3',[_vm._v("2004")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" BUCK begins as a motion graphics studio after a chance meeting on Craigslist in 2004. From day one, we work alongside gifted collaborators, investing in our people and our creative above all. ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"variation":"01","media":[
						_vm.$asset('image/graphic/overview-timeline-2006-00.webp'),
						_vm.$asset('image/graphic/overview-timeline-2006-01.webp'),
					]}}),_c('h3',[_vm._v("2006")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" We move from our starter space into our first purpose-built office in Los Angeles, CA. "),_c('br'),_c('br'),_vm._v(" We open BUCK’s New York office. ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"variation":"02","media":[
						_vm.$asset('image/graphic/overview-timeline-2008-00.webp'),
						_vm.$asset('image/graphic/overview-timeline-2008-01.webp'),
					]}}),_c('h3',[_vm._v("2008")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" Our first attempt at mixing miniatures with 3D in a series of spots for Toyota. ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"media":[_vm.$asset('image/graphic/overview-timeline-2010-00.webm')]}}),_c('h3',[_vm._v("2010")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" We create what we consider to be the first online explainer video for the documentary "),_c('em',[_vm._v("Waiting for Superman")]),_vm._v(". ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"media":[_vm.$asset('image/graphic/overview-timeline-2012-00.webp')]}}),_c('h3',[_vm._v("2012")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" Launch of our self-funded project "),_c('em',[_vm._v("Good Books")]),_vm._v(", winning over 15 awards. This is widely seen as a breakthrough moment for BUCK. ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"media":[_vm.$asset('image/graphic/overview-timeline-2014-00.webm')]}}),_c('h3',[_vm._v("2014")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" Deliver "),_c('em',[_vm._v("Intentions")]),_vm._v(" for Apple. ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"variation":"00","media":[
						_vm.$asset('image/graphic/overview-timeline-2015-00.webp'),
						_vm.$asset('image/graphic/overview-timeline-2015-01.webp'),
					]}}),_c('h3',[_vm._v("2015")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v("BUCK Sydney opens.")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"media":[_vm.$asset('image/graphic/overview-timeline-2017-00-long.mp4')]}}),_c('h3',[_vm._v("2017")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" First ever Emmy win for the opening sequence of David Blaine’s "),_c('em',[_vm._v("Spectacle of the Real")]),_vm._v(". ")])],1)],1),_c('li',[_c('ViewSceneOverviewTimelineItem',[_c('ViewTimelineMedia',{attrs:{"variation":"03","media":[
						_vm.$asset('image/graphic/overview-timeline-2018-00.webp'),
						_vm.$asset('image/graphic/overview-timeline-2018-02.gif'),
					]}}),_c('h3',[_vm._v("2018")]),_c('p',{staticClass:"type-highlight-2"},[_vm._v(" BUCK LA moves to Lacy Street campus. "),_c('br'),_c('br'),_vm._v(" Launch of our first AR app, Slapstick. ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }