import State from './State';

/**
 * Mutations are a synchronous way of modifying state. They are generally
 * called through Actions. It's best to think of them as setters.
 *
 * @see https://vuex.vuejs.org/guide/mutations.html
 *
 * @class Mutations
 * @package Store
 * @project Scrollyteller Template
 */
const mutations = {
	// /**
	//  * @param State state
	//  * @param IAuthResponse response
	//  * @return void
	//  */
	// authenticate(state: typeof State, response: IAuthResponse | boolean): void {
	// 	if (response === false) {
	// 		state.isLoggedIn = false;
	// 		state.token = undefined;
	// 		state.user = undefined;
	// 	} else if (typeof response != 'boolean') {
	// 		state.isLoggedIn = true;
	// 		state.token = response.auth.token;
	// 		state.user = response.user;
	// 	}
	// },

	/**
	 * @param State state
	 * @param boolean accept
	 * @return void
	 */
	cookies(state: typeof State, accept: boolean): void {
		state.cookies = accept;
	},

	/**
	 * @param State state
	 * @param string officeLocation
	 * @return void
	 */
	location(state: typeof State, officeLocation: string): void {
		state.officeLocation = officeLocation;
	},

	/**
	 * @param State state
	 * @param boolean value
	 * @return void
	 */
	setTransitioning(state: typeof State, value: boolean): void {
		state.transitioning = value;
	},

	/**
	 * @param State state
	 * @return void
	 */
	toggleMenu(state: typeof State): void {
		state.menuOpen = !state.menuOpen;
	},
};

export default mutations;
