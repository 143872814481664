import State from './State';

/**
 * Getters are used to calculate values based off state. They are generally
 * used for filtering or formatting data.
 *
 * @class Getters
 * @package Store
 * @project Scrollyteller Template
 */
const getters = {
	/**
	 * @param State state
	 * @return boolean
	 */
	showCookieBanner(state: typeof State): boolean {
		return state.cookies === undefined;
	},

	/**
	 * "theme-overview"
	 *
	 * @return string
	 */
	themeClass(state: typeof State): string {
		return 'theme-' + state.currentRouteName.toLowerCase().replace('page', '');
	},

	/**
	 * @param State state
	 * @return string
	 */
	transitionType(state: typeof State): string {
		const scrollY = window.scrollY;
		const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
		const isAtBottom = scrollY >= totalScroll - 500;

		// Primary menu is open, so fade
		if (state.menuOpen) {
			return 'fade';
		}

		// Going from left to right in terms of pages (if we're at the bottom of the page)
		else if (state.routeDirection == 1 && state.previousRouteName && isAtBottom) {
			return 'translate';
		}

		// Going backwards
		else if (state.routeDirection == -1) {
			return 'fade';
		}

		return 'none';
	},
};

export default getters;
