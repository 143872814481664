
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewLocationBar extends ViewBase {
		/**
		 * @return string[]
		 */
		protected get locationKeys(): string[] {
			return Config.App.OFFICE_LOCATIONS;
		}

		/**
		 * @return string[]
		 */
		protected get locationNames(): string[] {
			return this.locationKeys.map((location: string) => {
				return location
					.toLowerCase()
					.replace('-', ' ')
					.replace(/\b\w/g, (char) => char.toUpperCase());
			});
		}
	}
