
	import * as Library from '@/Library';
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Scene/2023
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewScene2023BCorpHero extends ViewBase {
		/**
		 * @type number
		 */
		public offsetRatio: number = 0;
	}
