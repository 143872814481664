
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewButtonNextPage extends ViewBase {
		/**
		 * @return string
		 */
		protected get nextPageName(): string {
			const currentPageName: string = this.$route.name || '';
			const indexOfPage: number = Config.App.PAGE_ORDER.indexOf(currentPageName);
			const nextIndex: number = indexOfPage + 1;
			const nextPageName: string = Config.App.PAGE_ORDER[nextIndex];

			return nextPageName;
		}
	}
