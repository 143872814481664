var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-scene-2023-scorecard background-light",attrs:{"id":"scorecard"}},[_c('div',{staticClass:"container-center container-xlarge"},[_c('div',{staticClass:"view-scorecard"},[_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 100}px)`,
				})},[_vm._m(0),_c('strong',[_vm._v("17.7")]),_c('span',[_vm._v("Score")])]),_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 150}px)`,
				})},[_vm._m(1),_c('strong',[_vm._v("32.3")]),_c('span',[_vm._v("Score")])]),_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 200}px)`,
				})},[_vm._m(2),_c('strong',[_vm._v("18.8")]),_c('span',[_vm._v("Score")])]),_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 100}px)`,
				})},[_vm._m(3),_c('strong',[_vm._v("14.4")]),_c('span',[_vm._v("Score")])]),_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 150}px)`,
				})},[_vm._m(4),_c('strong',[_vm._v("1.5")]),_c('span',[_vm._v("Score")])]),_c('div',{staticClass:"card background-b background-light",style:({
					transform: `translateY(${_vm.parallax.ratio.topToQuarter * 200}px)`,
				})},[_vm._m(5),_c('strong',[_vm._v("0.0")]),_c('span',[_vm._v("Score")])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Governance")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Workers")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Community")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Environments")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Customers")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('hgroup',[_c('h6',[_vm._v("Impact Area")]),_c('h5',[_vm._v("Disclosure Questionnaire")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card background-c background-dark color-2023-light fullsize"},[_c('hgroup',[_c('h5',[_vm._v("B Corp Impact Assessment Score")])]),_c('strong',[_c('span',[_vm._v("84.7")])])])
}]

export { render, staticRenderFns }