
	import * as Config from '@/Config';
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base';
	import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project BuckImpact2024
	 */
	@Component
	export default class ViewLoader extends ViewBase {
		/**
		 * @tpye string[]
		 */
		protected bindings: string[] = ['Handle_OnPreloaderComplete'];

		/**
		 * @type HTMLVideoElement
		 */
		@Ref('video')
		protected video!: HTMLVideoElement;

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Manager.Preloader.on('complete', this.Handle_OnPreloaderComplete);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Manager.Preloader.off('complete', this.Handle_OnPreloaderComplete);
		}

		/**
		 * @return Promise<void>
		 */
		@mounted
		public async start(): Promise<void> {
			// Wait
			await Utility.sleep(250);

			// Play
			this.video.play();
			Vue.set(this.className, 'animate-in', true);
		}

		/**
		 * @return void
		 */
		public stop() {
			Vue.set(this.className, 'animate-in', false);
			Vue.set(this.className, 'animate-out', true);

			this.video.pause();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnPreloaderComplete(): Promise<void> {
			const diff = Date.now() - this.mountedAt;
			const interval = Config.App.USE_PRELOADER ? Math.max(0, 1850 - diff) : 50;

			setTimeout(() => {
				this.$store.state.preloadComplete = true;
				this.stop();
			}, interval);
		}
	}
