import * as Config from '@/Config';
import RegisterComponents from '@/Utility/RegisterComponents';
import Vue from 'vue';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { assetRef } from '@/Manager/Asset';
import { gsap } from 'gsap';

/**
 * Setup ScrollTrigger for gsap
 */
gsap.registerPlugin(ScrollTrigger);

/**
 * Disable Vue settings
 */
Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.config.silent = true;

/**
 * Global event bus
 */
Vue.prototype.$bus = new Vue();

/**
 * Prototypes
 */
Vue.prototype.$asset = assetRef;

/**
 * Disable console logs if Config.Environment.IS_PRODUCTION
 */
if (Config.Environment.IS_PRODUCTION) {
	console.error = () => {
		/* Do nothing */
	};
	console.info = () => {
		/* Do nothing */
	};
	console.log = () => {
		/* Do nothing */
	};
	console.warn = () => {
		/* Do nothing */
	};
}

/**
 * Register components
 */
Vue.use(RegisterComponents, Config);
