import * as Config from '@/Config';
import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Catches the entrypoint zodiac and stores it in the Vuex store
 *
 * @return void
 */
export function Router(to: Route, from: Route, next: NavigationGuardNext): void {
	const currentPageIndex = Config.App.PAGE_ORDER.indexOf(to.name || '');
	const previousPageIndex = Config.App.PAGE_ORDER.indexOf(from.name || '');

	Store.state.currentRouteName = to.name || '';
	Store.state.previousRouteName = from.name || '';
	Store.state.routeDirection = Math.sign(currentPageIndex - previousPageIndex);

	// Save query params
	Store.state.headerUnstick = !!to.query.headerUnstick || !!to.query.headerunstick;
	Store.state.logoType = to.query.logoType || to.query.logotype || 'normal';

	// Continue to next middleware
	next();
}
