import * as Config from '@/Config';
import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Catches the entrypoint zodiac and stores it in the Vuex store
 *
 * @return void
 */
export function OfficeLocation(to: Route, from: Route, next: NavigationGuardNext): void {
	const officeLocation = to.query['location']?.toString() || 'united-states';

	// Compare to ffice locations
	if (Config.App.OFFICE_LOCATIONS.includes(officeLocation)) {
		Store.dispatch('setLocation', officeLocation);
	}

	// Continue to next middleware
	next();
}
