
	import * as Config from '@/Config';
	import * as Manager from '@/Manager';
	import { Component, Watch, Vue } from 'vue-property-decorator';
	import { Event, Utility } from 'buck-ts';
	import { created, mounted } from '@/Utility/Decorators';
	import { embedGoogleAnalytics } from '@/Library/Analytics';
	import { routerHistory } from '@/Router';

	@Component
	export default class App extends Vue {
		/**
		 * @return void
		 */
		@mounted
		protected attachScroll(): void {
			Manager.Scroll.attach();
		}

		/**
		 * @return void
		 */
		@mounted
		public embedAnalyticsIfWeHaveCookies(): void {
			if (this.$store.state.cookies) {
				embedGoogleAnalytics(Config.App.GOOGLE_ANALYTICS_ID);
			}
		}

		/**
		 * @return void
		 */
		@mounted
		protected globalDeltas(): void {
			Utility.Interval.add(() => {
				this.$store.state.delta60fps += 1;
				this.$store.state.delta30fps = ~~(this.$store.state.delta60fps / 2);
				this.$store.state.deltaFps = this.$store.state.delta60fps;
			}, 1000 / 60);
		}

		/**
		 * @return void
		 */
		@mounted
		protected setInitialTheme(): void {
			document.documentElement.classList.add(this.$store.getters.themeClass);
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @return Promise<void>
		 */
		@Watch('$route')
		protected async Handle_OnRouteChange(): Promise<void> {
			Event.Bus.dispatch('route:change', routerHistory.current);
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnLoaderComplete(): Promise<void> {
			this.$store.state.preloadComplete = true;
		}

		/**
		 * After animation finishes playing the enter motion
		 *
		 * @return Promise<void>
		 */
		protected async Handle_OnTransitionAfterEnter(): Promise<void> {
			// Log
			console.log('🔹 Enter: After ⏺');

			// Hotfix for Safari reflow bug
			// if (Config.Environment.IS_SAFARI && routerHistory.previous.name) {
			// 	window.scrollTo(0, 0);
			// 	location.reload();
			// }

			// Add theme to HTML so we can style overflow background
			document.documentElement.classList.add(this.$store.getters.themeClass);

			// Event trigger
			Event.Bus.dispatch('transition:after-enter:' + routerHistory.current.name);

			// Disable transitioning flag
			this.$store.commit('setTransitioning', false);
		}

		/**
		 * After animation finishes playing the leave motion
		 *
		 * @return Promise<void>
		 */
		protected async Handle_OnTransitionAfterLeave(): Promise<void> {
			// Log
			console.log('🔸 Leave: After ⏺');

			// We only do this if we're translating
			if (this.$store.getters.transitionType !== 'fade') {
				window.scrollTo(0, 0);
			}

			// Event trigger
			Event.Bus.dispatch('transition:after-leave:' + routerHistory.previous.name);
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnTransitionBeforeEnter(): Promise<void> {
			// Log
			console.log('🔹 Enter: Before o');

			// Scroll
			if (this.$store.state.menuOpen) {
				window.scrollTo(0, 0);
			}

			// Remove theme before navigation so it doesn't override styles
			if (routerHistory.history.length > 1) {
				document.documentElement.setAttribute('class', '');
			}

			// Disable transitioning flag
			this.$store.commit('setTransitioning', true);

			// Event trigger
			Event.Bus.dispatch('transition:before-enter:' + routerHistory.current.name);
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnTransitionBeforeLeave(): Promise<void> {
			// Log
			console.log('🔸 Leave: Before o');

			//
			if (this.$store.getters.transitionType === 'fade') {
				window.scrollTo(0, 0);
			}

			// Event trigger
			Event.Bus.dispatch('transition:before-leave:' + routerHistory.previous.name);
		}

		// endregion: Event Handlers
	}
