import { ActionContext } from 'vuex';
import { IStoreState } from './State';

/**
 * @type ActionContext<S, R>
 */
type Context = ActionContext<IStoreState, IStoreState>;

/**
 * Actions are generally meant to be the entrypoint for modifying state.
 *
 * They are asynchronous calls that can perform other tasks and then
 * trigger a mutation (synchronous).
 *
 * @see https://vuex.vuejs.org/guide/actions.html
 *
 * @class Actions
 * @package Store
 * @project Scrollyteller Template
 */
const actions = {
	// /**
	//  * @param Context context
	//  * @return void
	//  */
	// login(context: Context, authResponse: IAuthResponse): void {
	// 	context.commit('authenticate', authResponse);
	// },

	/**
	 * @param Context context
	 * @return void
	 */
	cookiesAccept(context: Context): void {
		context.commit('cookies', true);
	},

	/**
	 * @param Context context
	 * @return void
	 */
	cookiesDeny(context: Context): void {
		context.commit('cookies', false);
	},

	/**
	 * @param Context context
	 * @param string slug
	 * @return void
	 */
	setLocation(context: Context, slug: string): void {
		context.commit('location', slug);
	},

	/**
	 * @param Context context
	 * @return void
	 */
	toggleMenu(context: Context): void {
		context.commit('toggleMenu');
	},
};

export default actions;
