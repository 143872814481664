import * as Middleware from '@/Middleware';
import Vue from 'vue';
import VueRouter, { NavigationGuard, RawLocation, Route, RouteConfig } from 'vue-router';

// Attach VueRouter
// -----------------------------------------------------------------------------

Vue.use(VueRouter);

// Define Routing
// -----------------------------------------------------------------------------

const routes: Array<RouteConfig> = [
	{
		component: () => import('@/Page/Overview.vue'),
		name: 'PageOverview',
		path: '/',
	},
	{
		component: () => import('@/Page/2023.vue'),
		name: 'Page2023',
		path: '/2023',
	},
	{
		component: () => import('@/Page/Future.vue'),
		name: 'PageFuture',
		path: '/future',
	},
	{
		component: () => import('@/Page/People.vue'),
		name: 'PagePeople',
		path: '/people',
	},
	{
		component: () => import('@/Page/Planet.vue'),
		name: 'PagePlanet',
		path: '/planet',
	},
	{
		component: () => import('@/Page/Progress.vue'),
		name: 'PageProgress',
		path: '/progress',
	},
	{
		component: () => import('@/Page/Styleguide.vue'),
		name: 'PageStyleguide',
		path: '/styleguide',
	},
	{
		path: '/overview',
		redirect: '/',
	},
];

// Setup Router
// -----------------------------------------------------------------------------

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes: routes,
});

// Middleware hooks
// ---------------------------------------------------------------------------

const middlewares: NavigationGuard[] = [Middleware.OfficeLocation, Middleware.Router, Middleware.CloseMenu];

middlewares.forEach((middleware: NavigationGuard) => router.beforeEach(middleware));

// Route history
// -----------------------------------------------------------------------------

export const routerHistory: any = {
	current: {},
	history: [],
	previous: {},
};

router.beforeEach((to, from, next) => {
	routerHistory.previous = from;
	routerHistory.current = to;
	routerHistory.history.push(to);
	next();
});

// Override push and replace
// mk: Reject removed because it throws the visual error
// -----------------------------------------------------------------------------

type ErrorHandler = (err: Error) => void;
const originalPush: any = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (to: RawLocation): Promise<Route> {
	return new Promise((resolve: any, reject: ErrorHandler) => {
		originalPush.call(this, to, resolve); // , reject
	});
};

VueRouter.prototype.replace = function (to: RawLocation): Promise<Route> {
	return new Promise((resolve: any, reject: ErrorHandler) => {
		originalReplace.call(this, to, resolve); // , reject
	});
};

export default router;
