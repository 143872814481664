
	import ViewBase from '@/View/Base';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewDivider extends ViewBase {
		// Not implemented
	}
