
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base';
	import { Component } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project buck-impact-2024
	 */
	@Component
	export default class ViewHeader extends ViewBase {
		/**
		 * Automatically bind functions to scope
		 *
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnScroll'];

		/**
		 * If we have scrolled past the initial background. This indicates
		 * if we should change the nav color.
		 *
		 * @type boolean
		 */
		protected hasScrolledEnough: boolean = false;

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Manager.Scroll.on('scroll', this.Handle_OnScroll);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Manager.Scroll.off('scroll', this.Handle_OnScroll);
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnClickHome(): Promise<void> {
			this.$router.push('/');
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnClickMenu(): Promise<void> {
			this.$store.dispatch('toggleMenu');
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnScroll(): Promise<void> {
			this.hasScrolledEnough = window.scrollY > window.innerHeight * 2;
		}

		// endregion: Event Handlers
	}
