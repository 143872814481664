import * as Config from '@/Config';
import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Close global menu if its open
 *
 * @return void
 */
export function CloseMenu(to: Route, from: Route, next: NavigationGuardNext): void {
	setTimeout(() => {
		Store.state.menuOpen = false;
	}, 50);

	// Continue to next middleware
	next();
}
